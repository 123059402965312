.res-time {
  font-family: "Crimson Text", serif;
}

.user-chat-1,
.user-chat-2,
.staff-chat-1,
.staff-chat-2 {
  width: fit-content;
}

.user-chat-1 {
  border-radius: 20px 20px 20px 0px;
}
.user-chat-2 {
  border-radius: 0px 20px 20px 20px;
}

.staff-chat-1,
.staff-chat-2 {
  margin-left: auto;
  text-align: right;
}

.staff-chat-2 {
  border-radius: 20px 0px 20px 20px;
}
.staff-chat-1 {
  border-radius: 20px 20px 0px 20px;
}

.support-btn:hover {
  color: white;
  background-color: #454545;
  transition: all ease-in-out 0.3s;
}

.filter-modal {
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-form input[type="file"] {
    display: block;
    appearance: none;
    opacity: 0;
    z-index: 1;
    height: 70px;
    cursor: pointer;
  }
}

.testImage {
  border: 1.5px dashed #015588;
  height: 70px;
  width: 80px;
  border-radius: 8px;
  position: relative;
}
