.auth-button-disabled,
.ant-btn[disabled].auth-button-disabled {
  background: rgb(11, 11, 11);
  opacity: 0.4;
}

.btn-gradient-bg {
  background: linear-gradient(258.28deg, #079cbe 10.99%, #00497f 111.23%);
  &:hover,
  &:focus {
    background: linear-gradient(258.28deg, #10b5da 10.99%, #0563ac 111.23%);
    color: #abe3ee !important;
  }
}
.rounded-tab {
  border-radius: 20px 0px 0px 20px;
}
.last-tab {
  border-radius: 0px 20px 20px 0px;
}

.tab-button {
  border: 0;
  font-size: 14px;
  white-space: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a1a1a1;
}

.tab-btn {
  outline: 0;

  &::after {
    content: "";
    box-shadow: none !important;
    --ant-wave-shadow-color: none !important;
  }

  &.set {
    background-color: #000;
    color: #fff;
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}
