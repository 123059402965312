.bg-half {
  background-color: #ebeaf5;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;

  &.sm {
    background-size: 200px;
    background-position: 50% 90%;
  }
}

.mikro-bg {
  background-color: #ebeaf5;
}
.ant-input-affix-wrapper-has-feedback {
  .ant-input {
    background-color: rgb(214 201 201 / 3%);
    padding-top: 7px !important;
  }
}
.auth-input {
  width: 100%;
  border-radius: 0px 10px 10px 0px;
  border: 0;
  border-left: solid 2px #02123099;
  height: 56px;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.03);

  &.ant-select .ant-select-selector {
    height: 56px !important;
    border-radius: 0px 10px 10px 0px !important;
    border: 0;
    background: rgba(0, 0, 0, 0) !important;
    align-items: center;
    font-size: 16px;
    .ant-select-selection-search {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.multi {
    height: auto;
    min-height: 56px;

    &.ant-select .ant-select-selector {
      height: auto !important;
      min-height: 56px;
    }
  }

  .ant-input-number-input {
    width: 100%;
    border-radius: 0px 10px 10px 0px;
    background: rgba(0, 0, 0, 0.03);
    border: 0;
    border-left: solid 2px #02123099;
    height: 56px;
    font-size: 16px;
  }

  &.filled {
    border-color: #1377c4;
  }
  &.filled.ant-input {
    padding-top: 20px;
    font-size: 15px;
    border-color: #1377c4;
  }
  &.ant-input-password {
    input.ant-input {
      background: transparent;
    }
  }
  &.filled.ant-input-password {
    padding-top: 20px;
    font-size: 15px;
    border-color: #1377c4;
  }

  &.filled.ant-select {
    .ant-select-selector {
      padding-top: 20px;
      font-size: 15px;
      border-color: #1377c4;

      .ant-select-selection-search {
        padding-top: 0px;
      }
    }
  }

  &.filled.ant-picker {
    .ant-picker-input {
      padding-top: 20px;
      font-size: 15px;
      border-color: #1377c4;
    }
  }

  &.filled {
    .ant-input-number-input {
      padding-top: 20px;
      font-size: 15px;
      border-color: #1377c4;
    }
  }

  &:disabled {
    border-color: #d9d9d9;
  }
}

.auth-form .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background: rgba(0, 0, 0, 0.03);
  box-shadow: none;
}

.auth-input.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: rgba(0, 0, 0, 0.03);
  box-shadow: none;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background-color: transparent;
  background: rgba(0, 0, 0, 0.03);
  box-shadow: none;
}

.auth-label {
  display: none;
  &.filled {
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 12px;
    font-size: 12px;
    color: #1377c4;
  }
}

.auth-input:hover,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-affix-wrapper:hover {
  border-color: #1377c4;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #1377c4;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #1377c4;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1377c4;
}

.ant-radio-checked::after,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1377c4;
}

.ant-radio-inner::after {
  background-color: #1377c4;
}

.auth-check .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #1377c4;
}
.auth-check .ant-checkbox-checked::after {
  border-color: #1377c4;
}
.auth-check .ant-checkbox-checked {
  .ant-checkbox-inner {
    border-color: #1377c4;
    background-color: #fff;
  }
}
.auth-check .ant-checkbox-checked .ant-checkbox-inner::after {
  background-color: #1377c4;
  top: 15%;
  left: 15%;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  transform: none;
  border: 0;
}

.auth-input:focus,
.ant-input:focus,
.ant-picker-focused,
.ant-picker:hover {
  border-color: #1377c4;
  box-shadow: none;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: transparent;
  box-shadow: none;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #1377c4;
  box-shadow: none;
}

.auth-count-down {
  span {
    font-weight: 700;
  }
}

.splash-bg {
  background: #e8f7ff;
}

@media (max-width: 1024px) {
  .bg-half {
    height: 650px;
    background-size: 380px;

    &.sm {
      background-size: 170px;
    }
  }
}

@media (max-width: 576px) {
  .bg-half {
    height: 500px;
    background-size: 250px;

    &.sm {
      background-size: 120px;
    }
  }
}

/** New design **/

.input-field {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  font-size: 14px;
  background: transparent;
  color: #101828;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  .ant-input-number-input {
    width: 100%;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.03);
    border: 0;
    height: 48px;
    font-size: 16px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &.ant-input-password {
    input.ant-input {
      background: transparent;
    }
  }
}
label {
  color: #344054 !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
