@import url("https://use.typekit.net/jkv5qjh.css");

@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Black_byfyes.ttf");
  font-weight: 900;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: 800;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: 600;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: bolder;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Heavy_vcpqic.ttf");
  font-weight: bold;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335839/landing/fonts/Avenir/Avenir_Medium_r8yrlm.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Book_sfanvo.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Avenir;
  src: url("https://res.cloudinary.com/dbhhyyqd5/raw/upload/v1649335838/landing/fonts/Avenir/Avenir_Light_nzyszi.ttf");
  font-weight: 300;
}

* {
  font-family: "Muli", sans-serif;
}
.inter {
  font-family: "Inter";
}
b,
strong {
  font-weight: bold;
}

.general-color {
  color: #101828;
}

.mikro-gray {
  color: #a3abb7;
}

.mikro-dark {
  color: #00255a;
}

.mikro-dark-2 {
  color: #2c2c2c;
}

.mikro-dark-3 {
  color: #031b4a;
}

.mikro-blue {
  color: #00497f;
}

.mikro-blue-2 {
  color: #079cbe;
}

.mikro-blue-3 {
  color: #3359de;
}

.mikro-green {
  color: #00be7c;
}

.mikro-red {
  color: #ff0000;
}

.mikro-color {
  color: #ad074b;
}

* {
  outline-color: #ebeaf5;
}

a:hover {
  color: #c6b135;
}
a:focus {
  color: #abe3ee;
}

.landing {
  a:hover {
    color: #182f43;
  }
  a:focus {
    color: #12525f;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  p {
    height: -moz-fit-content;
  }
}

.back {
  -moz-box-align: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
}

.ant-btn-ghost:active,
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #2c2c2c;
  border-color: #2c2c2c;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #fff;
  border-color: #fff;
}

.ant-btn-primary:active,
.ant-btn-primary:focus {
  background: #fff;
  border-color: #fff;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #1377c4;
  border-color: #1377c4;

  &.ghost {
    color: unset;
    border: 0;
  }
}

.ant-btn:active {
  color: #1377c4;
  border-color: #1377c4;

  &.ghost {
    color: unset;
    border: 0;
  }
}

@media (max-width: 640px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }
}
.sp-color {
  color: #031b4a;
}
.list-pgn {
  .ant-pagination-item {
    border: 0;
    min-width: 28px;
    height: 28px;
    border-radius: 3px;

    &:hover {
      border: solid 1px #1377c4;
      a {
        color: #1377c4;
      }
    }
  }

  .ant-pagination-item-active {
    background-color: black;
    a {
      color: white;
    }
  }

  .ant-pagination-prev:hover,
  .ant-pagination-next:hover {
    a {
      color: #1377c4;
    }
    &.ant-pagination-disabled:hover {
      a {
        color: #ededed;
      }
    }
  }
}

.ant-image-preview-img-wrapper {
  display: flex !important;
  justify-content: center !important;
}

.Typewriter {
  .Typewriter__wrapper {
    font-weight: 700;
  }
}
.ant-switch-checked {
  background-color: #2c2c2c !important;
}
.ant-switch {
  border: 1px solid #2c2c2c;
  .ant-switch-handle {
    top: 1px;
  }
}
.ant-switch-checked > .ant-switch-handle:before {
  background: #fff;
}
.ant-switch-handle:before {
  background: #2c2c2c;
}
.landing {
  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
}

.auto-setl {
  background-color: #a1a1a1;
  &.ant-switch-checked {
    background-color: #3359de;
  }
}
.offer-btn:hover,
.offer-btn:focus {
  color: white;
  border-color: transparent;
}
.quill-grupp {
  &.filled {
    .ql-container.ql-snow {
      border-color: #1377c4;
    }
  }
  .ql-container.ql-snow {
    width: 100%;
    border-radius: 0px 10px 10px 0px;
    border: 0;
    border-left: solid 2px #000;
    height: 260px;
    background: rgba(0, 0, 0, 0.03);
  }
}
.table-date {
  &.ant-picker-focused {
    border-color: #a1a1a1 !important;
  }
  .ant-picker-active-bar {
    display: none;
  }
}
.custom-btn-shadow {
  box-shadow: 0px 10px 22px 0px rgba(2, 28, 67, 0.2);
}

.custom-rangepicker {
  & .ant-modal-content {
    overflow: hidden;
    border-radius: 0.75rem;
  }

  & .ant-modal-body {
    padding: 0;
  }
}
.custom-hover-text:hover {
  color: white !important;
}

.gm-style-iw-t {
  top: -50px;
}
