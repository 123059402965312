.user-card {
  background-image: url("../assets/svg-v2/user-backdrop.svg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.number-input {
  border: 0;
  &.ant-select .ant-select-selector {
    border-radius: 0px 10px 10px 0px !important;
    border: 0;
    padding: 10px 4px;
    background: rgba(0, 0, 0, 0.03) !important;
    align-items: center;
    font-size: 16px;
    border-left: solid 2px #000;
  }
}

.customer-collapse .ant-collapse-header {
  width: 100% !important;
}

.customer .ant-modal-content .ant-modal-header {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inherit;
}

.customer {
  .ant-modal-content {
    border-radius: 10px !important;
  }
}

.info_modal {
  .ant-modal-content {
    border-radius: 10px !important;
  }
}
.db-radio-menu {
  .ant-radio-checked .ant-radio-inner {
    border-color: #000;
  }

  .ant-radio-checked::after,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #000;
  }

  .ant-radio-inner::after {
    background-color: #000;
  }
}

/** New design */
.custom-shadow {
  border-radius: 12px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.custom-padding {
  padding: 24px;
}
.grupp-text {
  color: #475467;
}

.option-picker {
  background: transparent;
  text-transform: capitalize;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid var(--gray-300, #d0d5dd) !important;
    color: var(--gray-500, #667085);
    font-style: normal;
    background: transparent !important;
    .ant-select-selection-item {
      font-size: 14px !important;
      font-weight: 400;
    }
    // this is n ot need in new design
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        height: 48px !important;
      }
    }
  }

  // this is n ot need in new design
  &.graph {
    .ant-select-selector {
      .ant-select-selection-placeholder {
        line-height: 45px !important;
      }
    }
  }
  &.input-height {
    .ant-select-selector {
      height: 48px !important;
      background: transparent;
    }
  }
}
.status-card {
  border-radius: 16px;
  mix-blend-mode: multiply;
  padding: 3px 8px;
  &.green {
    border: 1px solid var(--success-200, #abefc6);
    background: var(--success-50, #ecfdf3);
    color: #067647;
  }
  &.red {
    border: 1px solid var(--error-200, #fecdca);
    background: var(--error-50, #fef3f2);
    color: #b42318;
  }
}
.input-search {
  border-radius: 8px !important;
  border: 1px solid var(--gray-300, #d0d5dd) !important;
  color: var(--gray-500, #667085);
  font-style: normal;
  .ant-input:placeholder-shown {
    margin-left: 5px;
  }
  .ant-input-suffix {
    align-items: flex-start;
  }
}
.map {
  div {
    border-radius: 8px;
  }
}

.new-modal {
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      border: 0;
      padding: 0;
      padding-bottom: 16px;
    }
  }
}

//check-this later
.tms-modal {
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-header {
      border-radius: 8px 8px 0 0;
      border: 0;
      padding: 0;
      padding-bottom: 16px;
      background: #231f20;
    }
  }
}
.new-image {
  border-radius: 200px;
  border: 4px solid var(--base-white, #fff);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.info-card-shadow {
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.tabs-box {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: #f9fafb;
  color: #1d2939;
  font-style: normal;
  font-weight: 600;
  .box {
    padding: 10px 16px;
    &:first-child {
      border-right: 1px solid var(--gray-300, #d0d5dd);
    }
    &.active {
      background: #fff;
      border-radius: 8px;
    }
  }
}
.upload-container {
  border-radius: 12px;
  border: 2px solid #eaecf0;
  background: #fff;
}
.doughnut-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem 0;
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.reprint {
  &.ant-input[disabled] {
    color: #000;
  }
}
