@import "./index.scss";
@import "../common/layout/layout";
@import "./buttons.scss";
@import "./auth.scss";
@import "./business.scss";
@import "../modules/Support/support";
@import "../modules/Transactions/transaction";
@import "../modules/Settings/settings";
@import "./analytics.scss";
@import "../modules/AccountForm/accountForm";

.landing * {
  font-family: "Avenir", sans-serif !important;
}
