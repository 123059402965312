.site-layout {
  margin-left: 200px;
  position: relative;
  background-color: #f9f9f9;
}

.mk-nav {
  position: fixed;
  background: #ffffff;
  width: calc(100% - 200px);
  left: 200px;
  top: 0;
  z-index: 30;

  &.sub-nav {
    top: 64px;
  }
}

.app-content {
  min-height: calc(100vh - 64px);
  padding: 30px 20px;
}

.sidebar {
  overflow: hidden auto;
  height: 100vh;
  position: fixed;
  left: 0px;
  z-index: 40;

  .logo {
    background-repeat: no-repeat;
    background-position: center;
    // background-image: url(../../assets/logo/logo.svg);
    background-size: 100px;
  }
}

/*.sidebar {
  -ms-overflow-style: none;
}

.sidebar {
  overflow: -moz-scrollbars-none;
}
.sidebar::-webkit-scrollbar {
  width: 0 !important;
}*/

.menu {
  .ant-menu-item {
    color: #969ba0;
    display: flex;
    align-items: center;
    height: 50px;

    a {
      color: #969ba0;
      font-size: 16px;
    }

    a.active,
    a:hover {
      color: #000000;
    }

    &:hover {
      color: #000000;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      transform: scaleY(0.0001);
      opacity: 0;
      border-left: solid 6px #000000;
      border-radius: 0 10px 10px 0;
    }

    &::after {
      border: 0;
    }

    .anticon {
      width: 20px;
      font-size: 20px;
    }

    &.ant-menu-item-selected {
      background-color: #fafbff;
      color: #000000;

      a {
        color: #000000;
      }

      &::before {
        transform: scaleY(1);
        opacity: 1;
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  .ant-menu-submenu {
    color: #969ba0;
    min-height: 50px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      transform: scaleY(0.0001);
      opacity: 0;
      border-left: solid 6px #000000;
      border-radius: 0 10px 10px 0;
    }

    .ant-menu-submenu-title {
      font-size: 16px;
      color: #969ba0;

      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #969ba0;
      }

      .anticon {
        width: 20px;
        font-size: 20px;
      }
    }
  }

  .ant-menu-submenu-selected,
  .ant-menu-submenu-active {
    background-color: #fff;
    color: #000000;

    .ant-menu-submenu-title {
      color: #000000;
      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #000000;
      }
    }

    .ant-menu-item-active,
    .ant-menu-submenu-title:hover {
      color: #000000;
      .ant-menu-submenu-arrow::before,
      .ant-menu-submenu-arrow::after {
        background: #000000;
      }
    }
  }
}

@media (max-width: 1024px) {
  .site-layout {
    margin-left: 0px;
  }

  .mk-nav {
    width: 100%;
    left: 0;

    &.sub-nav {
      height: auto;
      min-height: 64px;
      padding: 15px;
    }
  }

  .sidebar {
    flex: 0 !important;
    max-width: 200px !important;
    min-width: 0px !important;
    width: 0px !important;
    transition: width 0.1s;

    &.open {
      min-width: 200px !important;
      width: 200px !important;
    }
  }

  .app-content {
    padding: 25px 0;
    margin-top: 136px;
  }
}
.account-bd {
  background-image: url("../../assets/backgrounds/user-backdrop.png");
  background-size: cover;
  background-position-y: center;
  background-repeat: no-repeat;
}

@media (max-width: 640px) {
  .moblie-profile {
    right: 60% !important;
  }
}
.test:after {
  content: "\2807";
  font-size: 30px;
}
.bg-read {
  background: #f0f0f0;
}
.specbtn:hover {
  color: #000000;
  border: 0;
  outline: 0;
  background-color: none;
}
li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.detCol:hover {
  border-left: 2px solid black;
}
@media (min-width: 320px) {
  .xxsscreen {
    width: 40%;
  }
}
.ant-popover-buttons > .ant-btn {
  color: #000000;
}
.vendorProfile {
  img {
    object-fit: contain;
  }
}
