.tnx-card-bd {
  background-image: url("../../assets/backgrounds/tnx-backdrop.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.user-filter.ant-select-single:not(.ant-select-customize-input) {
  height: 100%;
  .ant-select-selector {
    background-color: transparent;
    border: none;
    height: inherit;
  }
  .ant-select-selection-placeholder {
    color: #9e9d9d;
    height: inherit;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
